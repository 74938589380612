import { Amplify, Auth, Hub,  Storage } from 'aws-amplify';
import { PhoneNumberField } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { GraphQLQuery } from '@aws-amplify/api';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { Alert, Button, CssBaseline, Dialog, DialogTitle, DialogContent, DialogActions, Divider, FormGroup, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { set, uniq } from 'lodash';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import '@aws-amplify/ui-react/styles.css';
import { listEvents, teamsByManagerCognitoId } from './graphql/queries';
import { createTeam, updateTeam, createPurchase, addIndividualToTeam } from './graphql/mutations';

import {  API, GRAPHQL_AUTH_MODE} from '@aws-amplify/api';
import { Event } from './API';
import { Team, UpdateTeamInput, CreateTeamMutation, UpdateTeamMutation, TeamsByManagerCognitoIdQuery, CreateTeamInput, Badge, ListEventsQuery, AddIndividualToTeamInput } from './API';
import './logo.css';  

import { ThemeProvider} from '@mui/material/styles';
import theme from './theme';

import {
  defaultDarkModeOverride,
  ThemeProvider as AmplifyThemeProvider,
} from '@aws-amplify/ui-react';



import { WithAuthenticatorProps } from '@aws-amplify/ui-react';

import { useEffect, useCallback, useState } from 'react';

import '@aws-amplify/ui-react/styles.css';

import { useMediaQuery } from '@mui/material';


import awsconfig from './updatedAwsConfig';

import EventData from './EventData';


const { REACT_APP_DATA_GRID_LICENCE_KEY } = process.env;

interface MyTypeRowReorderParams {
  dragRowIndex?: number;
  insertRowIndex?: number;
}

interface BadgeNameAndUrl {
  name: string;
  url: string;
}

Amplify.configure(awsconfig);

const amplifyTheme = {
  name: 'my-theme',
  overrides: [defaultDarkModeOverride],
};

// modal using material ui that provides a way to confirm the deletion of a team member


const DeleteConfirmationModal = ({ open, onClose, onDelete }: { open: boolean, onClose: () => void, onDelete: () => void }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Team Member</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Are you sure you want to delete this invitee / team member?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDelete} color="error">Delete</Button>
      </DialogActions>
    </Dialog>
  );
};


export function Manager({ signOut, user }: WithAuthenticatorProps) {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbar2Open, setSnackbar2Open] = useState(false);

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);

  const [rowIndex, setRowIndex] = useState<number>(0);

  const isMobile = useMediaQuery('(max-width:600px)');


  const [team, setTeam] = useState<Team | undefined>(undefined);

  const [teamName, setTeamName] = useState<string>('(Enter new team name here)');

  const [badges, setBadges] = useState<Array<BadgeNameAndUrl>>([]);

  const [sendSuccess, setSendSuccess] = useState<boolean>(false);


  const [dialCode, setDialCode] = useState('+61');

  const [events, setActiveEvents] = useState<Array<Event>>([]);

  const [openEvents, setOpenEvents] = useState<Array<Event>>([]);

  const [basePhoneNumber, setBasePhoneNumber] = useState('');

  // a variable if the hostname is localhost or starts with 192.168. or 10. or dev
  const isDev = window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.') || window.location.hostname.startsWith('10.') || window.location.hostname.startsWith('dev');

  const startTestEvent =async (e:any) => {
    e.preventDefault();

    const apiName = 'startTestEvent';
    const path = '/';
    const myInit = {
      body: {
        team: team,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };

    try {
      const response = await API.post(apiName, path, myInit);

      // update the team react state to reflect the locked status
      setTeam((prevTeam) => prevTeam ? { ...prevTeam, locked: true } : prevTeam);

      console.log(JSON.stringify(response));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  const addPerson = async() => {
    const phoneNumber = dialCode + basePhoneNumber;
    const apiName = 'invite';
    const path = '/bySMS';
    const myInit = {
      body: {
        phoneNumber: phoneNumber,
        team: {
          name: teamName,
          id: team?.id,
        },
        baseUrl: window.location.origin,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
  
    try {
      // check if the phone number is valid
      if (!isMobilePhone(dialCode+basePhoneNumber.replace(/^0+/, ''))) {
        console.log('not a valid phone number');
        return;
      }
      const response = await API.post(apiName, path, myInit);
      console.log(JSON.stringify(response));

      console.log('team', team);
      // add the member to the team
      if (team) {
        // use the addIndividualToTeam mutation to add the person to the team
        const addIndividualToTeamInput: AddIndividualToTeamInput = {
          id: team.id,
          member: phoneNumber
        };

        console.log('addIndividualToTeamInput', addIndividualToTeamInput);

        const addIndividualToTeamResult = await API.graphql<GraphQLQuery<any>>({
          query: addIndividualToTeam,
          variables: { input: addIndividualToTeamInput }
        });

        // update the team react state to reflect the new member
        setTeam(addIndividualToTeamResult.data!.addIndividualToTeam as Team);

        // update the rows react state to reflect the new member
        const newRows = rows;
        newRows.push({id: newRows.length, phoneNumber: phoneNumber, name: '', hasBaton: 'false', delete: '⌫'});
        setSendSuccess(true);
        setSnackbar2Open(true);

        // set a timeout to close the snackbar
        setTimeout(() => {
          setSnackbar2Open(false);
        }, 2000);

      } else {
        console.log('team is undefined');
        setSendSuccess(false);
        setSnackbar2Open(true); // don't auto close the snackbar
      }


    } catch (error) {
      console.log(JSON.stringify(error));
      setSendSuccess(false);
      setSnackbar2Open(true); // don't auto close the snackbar
    }
  }

  const columns = [
    { name: 'phoneNumber', header: 'number', minWidth: 20, defaultFlex: 1 },
    { name: 'name', header: 'name', editable: true, minWidth: 20, defaultFlex: 1 },
    { name: 'hasBaton', header: 'baton', minWidth: 20, defaultFlex: 1 },
    { name: 'delete', header: 'delete', minWidth: 20, defaultFlex: 1}
  ]

  const [rows, setRows] = useState<Array<any>>([]);

  const onEditComplete = useCallback(async ({ value, columnId, rowIndex }: any) => {
    // if columnIf is name update and mutate team.names
    if (columnId === 'name') {
      let newRows = rows;
      newRows[rowIndex].name = value;
      setRows(newRows);
      // construct members array from rows
      const members = newRows.map((row:any) => {
        return row.phoneNumber;
      });
      // construct names array from rows
      const names = newRows.map((row:any) => {
        return row.name;
      });
      // mutate team.members
      if (team) {
        const updatedTeamInput: UpdateTeamInput = {
          id: team.id,
          members: members,
          names: names
        };

        const updateTeamResult = await API.graphql<GraphQLQuery<UpdateTeamMutation>>({
          query: updateTeam,
          variables: { input: updatedTeamInput },
          authMode: GRAPHQL_AUTH_MODE.API_KEY
        });

        setTeam(updateTeamResult.data!.updateTeam as Team);
      }
    }

  }, [team, rows]);

  const onRowReorder = useCallback(async ({ dragRowIndex, insertRowIndex }: MyTypeRowReorderParams) => {

    if (team && team.locked) {
      return; // do not allow reordering if the team is locked
    }

    if (dragRowIndex === insertRowIndex) {
      return;
    }
    
    if (dragRowIndex === undefined || insertRowIndex === undefined) {
      return;
    }
    let newRows = rows;

    console.log('rows', rows, dragRowIndex, insertRowIndex);

    // use dragRowIndex and insertRowIndex to reorder newRows
    const draggedRow = newRows[dragRowIndex];
    newRows.splice(dragRowIndex, 1);
    newRows.splice(insertRowIndex, 0, draggedRow);
    
    console.log('newRows', newRows);

    setRows(newRows);

    // construct members array from rows
    const members = newRows.map((row:any) => {
      console.log('row',row);
      return row.phoneNumber;
    });

    // construct names array from rows
    const names = newRows.map((row:any) => {
      console.log('row',row);
      return row.name;
    });

    // mutate team.members
  
    if (team) {
      const updatedTeamInput: UpdateTeamInput = {
        id: team.id,
        members: members,
        names: names
      };

      const updateTeamResult = await API.graphql<GraphQLQuery<UpdateTeamMutation>>({
        query: updateTeam,
        variables: { input: updatedTeamInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      });

      setTeam(updateTeamResult.data!.updateTeam as Team);
    }

  }, [rows, team]);

  const onDelete = async () => {
    let newRows = rows;

    let batonHolder = team!.batonHolder;

    // pass the baton to the next person in the list if the person being deleted has the baton, treat the list as a circular list
    const batonIndex = newRows.findIndex((row:any) => row.phoneNumber === team?.batonHolder);
    if (batonIndex !== -1 && newRows.length > 1) {
      const nextBatonIndex = (batonIndex + 1) % newRows.length;
      batonHolder = newRows[nextBatonIndex].phoneNumber;
    }

    // update the baton holder in the rows
    newRows = newRows.map((row:any) => {
      return { ...row, hasBaton: String(row.phoneNumber === batonHolder) };
    });


    // use rowIndex to delete the row
    newRows.splice(rowIndex, 1); // remove the row


    setRows(newRows);
    // construct members array from rows
    const members = newRows.map((row:any) => {
      return row.phoneNumber;
    });
    // construct names array from rows
    const names = newRows.map((row:any) => {
      return row.name;
    });
    // mutate team.members
    if (team) {
      const updatedTeamInput: UpdateTeamInput = {
        id: team.id,
        members: members,
        batonHolder: batonHolder,
        names: names
      };

      const updateTeamResult = await API.graphql<GraphQLQuery<UpdateTeamMutation>>({
        query: updateTeam,
        variables: { input: updatedTeamInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      });

      setTeam(updateTeamResult.data!.updateTeam as Team);
      setDeleteConfirmationModalOpen(false);
    }
  }


  const onCellClick = useCallback(async (event: any, cellProps: { columnIndex: any; rowIndex: any; }) => {
    const { columnIndex, rowIndex } = cellProps

    // if the delete button is not clicked, do nothing
    if (columnIndex !== 4) {
      return;
    }
    setRowIndex(rowIndex);
    setDeleteConfirmationModalOpen(true);

  }, []);


  useEffect(() => {
    // declare the data fetching function



    const fetchTeam = async () => {
        try {


            const myTeamResult = await API.graphql<GraphQLQuery<TeamsByManagerCognitoIdQuery>>({
              query: teamsByManagerCognitoId,
              variables: { managerCognitoId: user?.username },
              authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            });

            if (myTeamResult.data!.teamsByManagerCognitoId!.items[0]) {
              const team = myTeamResult.data!.teamsByManagerCognitoId!.items[0] as Team;
              setTeam(team);
              setTeamName(team!.name!);
              // construct row of form {id: 0, phoneNumber: '+61412345678', __reorder__: '+61412345678'} from myTeamResult.data!.teamsByManagerCognitoId!.items[0].members
              const members = myTeamResult.data!.teamsByManagerCognitoId!.items[0].members!;
              const names = myTeamResult.data!.teamsByManagerCognitoId!.items[0].names!;
              const uniqueMemberPhoneNumbers = uniq(members);
              const rows = uniqueMemberPhoneNumbers.map((phoneNumber: any, index: number) => {
                return {id: index, phoneNumber: phoneNumber, name: names && names[index] ? names[index] : '', hasBaton: String(phoneNumber === team.batonHolder), delete: '⌫' };
              });
              setRows(rows);
              const badgesOrFilter = team.badges?.map((id) => {
                return { id: { eq: id } } 
              });

              if (badgesOrFilter) {
                const listBadges = /* GraphQL */ `query ListBadges {
                  listBadges (filter: {or: ${JSON.stringify(badgesOrFilter).replace(/"id"/g,"id").replace(/"eq"/g,"eq")}}) {
                    items {
                      id
                      name
                      s3Key
                    }
                  }
                }`;
          
                const listBadgesResult = await API.graphql<GraphQLQuery<any>>({
                  query: listBadges
                });

                const badges = listBadgesResult.data!.listBadges!.items as Array<Badge>;
                const badgeNamesAndUrls = await Promise.all(badges.map(async (badge) => {
                  const url = await Storage.get(badge.s3Key!);
                  const name = badge.name!;
                  return {url, name};
                }));

                setBadges(badgeNamesAndUrls);
              }

              const myEventResult = await API.graphql<GraphQLQuery<ListEventsQuery>>({
                query: listEvents,
                // filter by (!event archieved or archived is null) and event.teams.includes(team.id)
                variables: { filter: { and: [{ not: { archived: { eq: true } } }, { teams: { contains: team.id } }] } },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
              });

              console.log(myEventResult);

              const events = myEventResult.data!.listEvents!.items as Array<Event>;
              setActiveEvents(events);

              const myOpenEventsResult = await API.graphql<GraphQLQuery<ListEventsQuery>>({
                query: listEvents,
                authMode: GRAPHQL_AUTH_MODE.API_KEY
              });
      
              let openEvents = myOpenEventsResult.data!.listEvents!.items as Array<Event>;
              console.log(openEvents);
      
              // return only open events that are not archived and have not started and if the teams field exists, do not include the team
              openEvents = openEvents.filter((event) => {
                return !event.archived && !event.started && (!event.teams || !event.teams.includes(team.id));
              });
              console.log(openEvents);
              setOpenEvents(openEvents);
            }
        } catch (err) {
          throw err;
        }
    }
  
    if (user && user.username) {    // call the function

      fetchTeam()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [user]);

  const setTeamNameSubmit = async (event: any) => {
    event.preventDefault();
    if (team) { // team exists, mutate
      console.log(JSON.stringify(team));
      const updatedTeamInput: UpdateTeamInput = {
        id: team.id,
        managerCognitoId: team.managerCognitoId,
        managerPhoneNumber: team.managerPhoneNumber,
        members: team.members,
        name: teamName,
      };

      const updateTeamResult = await API.graphql<GraphQLQuery<UpdateTeamMutation>>({
        query: updateTeam,
        variables: { input: updatedTeamInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      });

      setTeam(updateTeamResult.data!.updateTeam as Team);
      setTeamName(updateTeamResult.data!.updateTeam!.name!);


    } else { // team doesn't exist, create
      console.log('creating team');

      try {
        const createTeamInput: CreateTeamInput = {
          managerCognitoId: user!.username!,
          managerPhoneNumber: user!.attributes!.phone_number,
          name: teamName
        };

        const createTeamResult = await API.graphql<GraphQLQuery<CreateTeamMutation>>({
          query: createTeam,
          variables: { input: createTeamInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        });

        setTeam(createTeamResult.data!.createTeam as Team);
        setTeamName(createTeamResult.data!.createTeam!.name!);
    } catch (e) {
      console.log(JSON.stringify(e));
    }

  
    }
  }

  return (
    <AmplifyThemeProvider theme={amplifyTheme} colorMode="dark">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='logo-container'>
        <div className='logo'></div>
      </div>
    <Paper elevation={0} sx={{ width: '95%', m: 'auto', mt: '20px'}}>
          <Typography variant="h3" gutterBottom sx={{ fontSize: isMobile ? '20px' : '40px' }}> Team Management {user?.attributes?.name}{team ? ` for '${team.name}'` : ''}</Typography>
      <Typography variant="body1" gutterBottom>

      {badges.map((badge: any) => {
        return <img src={badge.url} alt={badge.name} width="100px" height="100px" />
      })}


      <DeleteConfirmationModal open={deleteConfirmationModalOpen} onClose={() => setDeleteConfirmationModalOpen(false)} onDelete={onDelete} />
      


      <form onSubmit={setTeamNameSubmit}>
        <FormGroup>
          <TextField type="text" 
                  color="primary" 
                  value={teamName}
                  onChange={(e)=>{
                    setTeamName(e.target.value);
                  }} />
            
          <Button variant="outlined" color="primary" type="submit" onClick={()=> setSnackbarOpen(true)}>{team ? <>Add/change team name</> : <>Create Team</>}</Button>
        
          <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={6000} 
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            style={{ 
                position: 'fixed', 
                top: '20%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)' 
              }}
            >
            <Alert onClose={() => { console.log('Alert closed'); setSnackbarOpen(false); }} severity="success" sx={{ width: '100%' }}>
              Team name added/changed!
            </Alert>
          </Snackbar>

          <h1 style={{ fontSize: isMobile ? '20px' : '40px' }}>Team Member Registration</h1>

        </FormGroup>
      </form>

      {/* an MUI list of all the open events, with a button to add the team to the event */}
      {team && !team.locked && openEvents && openEvents.length > 0 ?
      <>
      <h1 style={{ fontSize: isMobile ? '20px' : '40px' }}>Open Events</h1>
      <ul>
        {openEvents.map((event: any) => {
          return <li key={event.id}>
            {event.name}, leg length: {event.minimumDistance}km, number of teams: {(event.teams ? event.teams.length : 0)}
            <Button 
              variant="contained" 
              color="primary" 
              onClick={async () => {
                
                // create a new Purchase object
                const purchase = {
                  team: team?.id,
                  event: event.id,
                  status: false
                };

                // add the Purchase object to the database using the createPurchase mutation
                const createPurchaseResult = await API.graphql<GraphQLQuery<any>>({
                  query: createPurchase,
                  variables: { input: purchase },
                  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
                });

                // get the id of the Purchase object
                const purchaseId = createPurchaseResult.data!.createPurchase.id;

                // construct the URL to redirect to
                // of the form https://buy.stripe.com/test_5kA28L3uq3wy7y8fYY?utm_source={utm_source}&utm_content={utm_content}

                const stripeUrl = isDev ? `https://buy.stripe.com/test_cN214H2qm1oq19K4gh?&utm_content=${purchaseId}`: `https://buy.stripe.com/test_5kA28L3uq3wy7y8fYY?&utm_content=${purchaseId}`;

                // redirect to the stripe URL
                window.location.href = stripeUrl;
                

              }}
              style={{ marginLeft: '30px', backgroundColor: 'black', color: 'white' }}>
              Add team to event
            </Button>
          </li>
        })}
      </ul></> : <></>}

      {team && !team.locked ? <> 
      <h1 style={{ fontSize: isMobile ? '20px' : '40px' }}>Start Test Event</h1>
      <p>Click the button below to start a test event. This will lock the team and prevent further changes to the team members other than deletion.</p>
      <form onSubmit={startTestEvent}>
        <Button variant="outlined" color="primary" type="submit">Start Test Event</Button>
      </form>
      </> : <></>}

      {team && !team.locked ? <>
  <form onSubmit={(e) => { e.preventDefault(); addPerson(); }}>
    <FormGroup>
      <PhoneNumberField
        autoComplete="username"
        label="Enter a person's mobile phone number to add them to your team via sms."
        name="phone_number"
        defaultDialCode="+61"
        onChange={(e: any) => setBasePhoneNumber(e.target.value.replace(/^0+/, ''))}
        onDialCodeChange={(e: any) => setDialCode(e.target.value)}
        hasError={(basePhoneNumber.length > 0 && !isMobilePhone(dialCode+basePhoneNumber.replace(/^0+/, '')))}
        errorMessage={'Not a valid phone number! 😱'}
      />
      {isMobilePhone(dialCode+basePhoneNumber.replace(/^0+/, '')) && !team.members?.includes(dialCode+basePhoneNumber.replace(/^0+/, '')) ? 
      (<Button variant="outlined" color="primary" type="submit">Add team member by SMS</Button>) : (<></>)}
    </FormGroup>
  </form>

  </>
: <></>}

<Snackbar 
  open={snackbar2Open} 
  autoHideDuration={6000} 
  onClose={() => setSnackbar2Open(false)}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  style={{ 
      position: 'fixed', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)' 
    }}
  >
  {sendSuccess ? (
  <Alert onClose={() => { console.log('Alert closed'); setSnackbar2Open(false); }} severity="success" sx={{ width: '100%' }}>
    Invite sent successfully!
  </Alert>) : (
  <Alert onClose={() => { console.log('Alert closed'); setSnackbar2Open(false); }} severity="error" sx={{ width: '100%' }}>
    Invite failed to send!
  </Alert>)}
</Snackbar>

<p style={{ textAlign: 'center' }}>
  Team members who have accepted your invitation will appear below. You will not see them listed if they haven't accepted the link in the SMS sent to them. (Drag and drop to reorder them).
</p>

      <ReactDataGrid dataSource={rows} columns={columns} onRowReorder={onRowReorder} reorderColumns={false} onCellClick={onCellClick} onEditComplete={onEditComplete} licenseKey={REACT_APP_DATA_GRID_LICENCE_KEY!}/>
     
     
      </Typography>
      {events && events.length > 0 ? 
      <Typography variant="body1" gutterBottom>
        Team is signed up to the following events:
        <ul>
          {events.map((event: any) => {
            return <li key={event.id}>
              {event.name}
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => window.open(isDev ? 'https://dev.twelfth-monkey.com' : 'https://twelfth-monkey.com', '_blank')}
                style={{ marginLeft: '30px', backgroundColor: 'black', color: 'white' }}>
                View stats in new page for better resolution.
                
              </Button>

              
              </li>
          }
          )}
        </ul>
      </Typography> : <></>}
     
      <Divider />

      

      {events && events.length > 0 && events.map((event: any) => {
    return (
      <EventData id={event.id} />
    );
  })}
     

      <Button onClick={signOut}>Sign out</Button>
    </Paper>
    </ThemeProvider>
    </AmplifyThemeProvider>
  );
}

Hub.listen('auth', (data) => { 
  if (data.payload.event === 'signIn_failure') {
      console.log(data);
  }
})

export default withAuthenticator(Manager);